import React from 'react';
import SEO from '../components/seo';
import { Flex, Box, Heading, Text } from 'rebass';
import Divider from '../components/divider';
import Wrapper from '../components/wrapper';
import { ThemeProvider } from 'emotion-theming';
import theme from '../components/theme';
// @ts-ignore
import GridLogoLight from '../assets/svgs/GridLogoLight.svg';
import { navigate } from '@reach/router';

const NotFoundPage = ({ error }) => (
  <ThemeProvider theme={theme}>
    <SEO title="404: Not found" />
    <Wrapper>
      <Box margin={'auto'}>
        <Flex as={'nav'} height={70} alignItems={'center'}>
          <GridLogoLight
            onClick={() => {
              navigate('/', {
                state: { overlay: { visible: false, path: null } },
              });
            }}
          />
        </Flex>
        <Divider />
        <Box py={68}>
          <Heading variant={'text.h700Medium'} mb={24}>
            Something went wrong
          </Heading>
          <Text variant={'text.h400Regular'}>
            We’re sorry for the inconvenience. Please refresh the page and try
            again.
          </Text>
          {process.env.GATSBY_VISOR_GETGRIDAPP_DEBUG ? (
            <Box my={2}>
              <pre
                css={{
                  whiteSpace: 'pre-wrap',
                  fontSize: '110%',
                }}
              >
                {error?.toString()}
              </pre>
            </Box>
          ) : null}
        </Box>
      </Box>
    </Wrapper>
  </ThemeProvider>
);

export default NotFoundPage;
